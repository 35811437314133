import { useCallback } from "react";

/**
 * copied from @reach/utils/compose-refs
 */

function assignRef(ref, value) {
  if (ref == null) {
    return;
  }
  if (typeof ref === "function") {
    ref(value);
  } else {
    try {
      ref.current = value;
    } catch (error) {
      throw new Error(`Cannot assign value "${value}" to ref "${ref}"`);
    }
  }
}

export default function useComposedRefs(...refs) {
  return useCallback(
    node => {
      for (let ref of refs) {
        assignRef(ref, node);
      }
    },
    [refs]
  );
}
